import type { ToastLevel } from './toast-types'

export class ToastItem extends HTMLElement {
    constructor() {
        super()
        const template = document.getElementById('toast-template') as HTMLTemplateElement
        const templateContent = template.content

        const shadowRoot = this.attachShadow({ mode: 'open' })
        shadowRoot.appendChild(templateContent.cloneNode(true))
    }

    setMessage(message: string) {
        const messageSlot = this.shadowRoot.querySelector('p')
        const messageElement = document.createElement('span')
        messageElement.textContent = message
        messageSlot.appendChild(messageElement)
    }

    setLevel(level: ToastLevel) {
        const toast = this.shadowRoot.querySelector('.toast')
        toast.classList.add(level.toLocaleLowerCase())
    }
}
